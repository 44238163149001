import  { useEffect, useState } from 'react';
import {
  Container,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Tooltip,

} from '@mui/material';
import { useSelector } from 'react-redux';
import AccountService from '../../core/services/account.service';
import { Licensing } from '../agreements/licensing';
import LoaderWidget from '../../core/widgets/loader/loader.widget';

import {
  BoxMembership,
  TitleMembership,
  LabelTitle,
  LabelText,
  // BtnTurnOff,
  TableTitle,
  TableInformation,
  BoxDataMemberShip
} from '../assets/styles/membership.styles';
import { DialogComponent } from '../../core/layouts/widgets/header/header.styles';
import { ButtonCheckout } from '../questionnaire-wizard/payment/payment.style';
import { Edit } from '@mui/icons-material';
import UpdatePaymentMethod from './updatePaymentMethod.dialog';

const SettingsPage = () => {
  const { user } = useSelector((state) => state.user);
  const { current } = useSelector(state => state.business);
  const accountService = new AccountService();
  const [membership, setMembership] = useState({})
  const [turnOffRenewal, setTurnOffRenewal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderMembership, setLoaderMembership] = useState(false);
  const [openUpdatePaymentMethod, setIsOpenUpdatePaymentMethod] = useState(false);

  const packages = membership.packages ? Object.keys(membership.packages).map(key => membership.packages[key]) : [];
 
  const getMembership = async () => {
    setLoader(true);
    try {
      accountService.setAccessToken(user.token)
      const response = await accountService.getMembership(current.id);
      setMembership(response.data.membership.find((item) => item.id === current.id));
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }

  const cancelMembership = async () => {
    setLoaderMembership(true);
    try{
      accountService.setAccessToken(user.token)
      await accountService.cancelMembership();
      setLoaderMembership(false);
      setTurnOffRenewal(false);
    }catch (error){
      setLoaderMembership(false);
      setTurnOffRenewal(false);
    }
  }

  useEffect(() => {
    getMembership();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current])

  return (
    <>
      <Container>
        <Grid container spacing={2} sx={{ marginTop: '2em' }}>
          <Grid item xs={12} md={3}>
            <Licensing />
          </Grid>
          <Grid item xs={12} md={9}>
            <BoxMembership>
              <TitleMembership>Membership</TitleMembership>
              <BoxDataMemberShip>
                <Grid container spacing={4}>
                  <Grid item md={12}>
                    <LabelTitle>Gym Name</LabelTitle>
                    <LabelText>{membership.name}</LabelText>
                  </Grid>
                  <Grid item md={3}>
                    <LabelTitle>Street</LabelTitle>
                    <LabelText>{membership.address}</LabelText>
                  </Grid>
                  <Grid item md={6}>
                    <Grid container>
                      <Grid item md={6}>
                        <LabelTitle>City</LabelTitle>
                        <LabelText>{membership.city}</LabelText>
                      </Grid>
                      <Grid item md={6}>
                        <LabelTitle>State</LabelTitle>
                        <LabelText>{membership.state}</LabelText>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3}>
                    <LabelTitle>Zip</LabelTitle>
                    <LabelText>{membership.zip}</LabelText>
                  </Grid>
                  <Grid item md={12}>
                    <Box
                      sx={{ borderBottom: '1px solid rgba(1,1,1,.15)' }}
                    ></Box>
                  </Grid>
                  <Grid item md={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Box>
                        <LabelTitle>Payment Method</LabelTitle>
                        <LabelText>{`**** **** **** ${membership.pmLastFour || '****'}`}</LabelText>
                      </Box>
                      <Box>
                        <Tooltip title="Update payment method">
                          <IconButton
                            onClick={() => setIsOpenUpdatePaymentMethod(true)}
                            size='small'
                            sx={{ color: (theme) => theme.palette.common.black }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <Box
                      sx={{ borderBottom: '1px solid rgba(1,1,1,.15)' }}
                    ></Box>
                  </Grid>
                  <Grid item md={3}>
                    <LabelTitle>Total Monthly Payment</LabelTitle>
                    <LabelText>${membership.totalMonthlyPayment}/month</LabelText>
                  </Grid>
                  <Grid item md={9}>
                    <LabelTitle>Coverage</LabelTitle>
                    <LabelText>
                      {membership.providers?.map((item, i) => {
                        if (packages.length > 0 && item === 'FITRADIO') return null; 
                        return(
                          <span key={i}>{`${item} `}</span>
                        )
                      })}
                    </LabelText>
                  </Grid>
                  <Grid item md={12}>
                    <Box
                      sx={{
                        borderBottom: '1px solid rgba(1,1,1,.15)',
                        marginBottom: '1em',
                      }}
                    ></Box>
                  </Grid>

                  {/* {membership.isAutoRenewal && 
                    <BtnTurnOff
                      onClick={() => setTurnOffRenewal(true)}
                    >
                      Turn Off Annual Renewal
                    </BtnTurnOff>
                  } */}

                  {
                    (membership.packages?.length > 0)
                      &&
                        <Grid item md={12}>
                          <LabelTitle>PACKAGES</LabelTitle>
                        </Grid>

                  }

                  {
                    (membership.packages?.length > 0) && Object.keys(membership?.packages).map((key) => (
                      <Grid item md={12} key={key}>
                        <Grid container>
                          <Grid item md={2}>
                            <LabelTitle>Package name</LabelTitle>
                            <LabelText>{membership?.packages[key].name}</LabelText>
                          </Grid>
                          <Grid item md={3}>
                            <LabelTitle>PROs</LabelTitle>
                            <LabelText>{membership?.packages[key].provider}</LabelText>
                          </Grid>
                          <Grid item md={2}>
                            <LabelTitle>Start Date</LabelTitle>
                            <LabelText>{membership?.packages[key].startDate}</LabelText>
                          </Grid>
                          <Grid item md={2}>
                            <LabelTitle>Renewal Date</LabelTitle>
                            <LabelText>{membership?.packages[key].renewalDate}</LabelText>
                          </Grid>
                          <Grid item md={2}>
                            <LabelTitle>Total Amount</LabelTitle>
                            <LabelText>{membership?.packages[key].totalAmount}</LabelText>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  }
                </Grid>
              </BoxDataMemberShip>
              {
                membership.providersDetail?.length > 0 && (
                <Box sx={{
                  overflowX: 'auto',
                }}>
                  <TableTitle>My Coverage through FITRADIO</TableTitle>
                  <TableInformation>
                    <TableHead>
                      <TableRow>
                        <TableCell>Annual Licenses</TableCell>
                        <TableCell align='left'>Monthly Rate</TableCell>
                        <TableCell align='left'>Start Date</TableCell>
                        <TableCell align='left'>Renewal Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {membership.providersDetail?.map((row, i) => (
                        <TableRow
                          key={i}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component='th' scope='row'>
                            {row.annualLicenses}
                          </TableCell>
                          <TableCell align='left'>{`$ ${row.monthlyRate}`}</TableCell>
                          <TableCell align='left'>{row.annualStartDate}</TableCell>
                          <TableCell align='left'>{row.annualRenewalDate}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </TableInformation>
                </Box>
                )
              }
              {
                packages.length > 0 && (
                  <Box sx={{
                    overflowX: 'auto',
                    marginTop: '2em'
                  }}>
                    <TableInformation>
                      <TableHead>
                        <TableRow>
                          <TableCell>Packages</TableCell>
                          <TableCell align='left'>Monthly Rate</TableCell>
                          <TableCell align='left'>Start Date</TableCell>
                          <TableCell align='left'>Renewal Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {packages.map((p, i) => {
                          const packageProviders =  p.provider.split(',').map(item => item.trim()).filter(item => item !== 'FITRADIO').join(', ');
                          return (<TableRow
                            key={i}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component='th' scope='row' style={{display: 'flex', flexDirection: 'column'}}>
                              <Typography fontSize={14} fontWeight={500}>{p.name}</Typography>
                              <Typography fontSize={14} fontWeight={500}>{packageProviders}</Typography>
                            </TableCell>
                            <TableCell align='left'>{`$ ${p.totalAmount}`}</TableCell>
                            <TableCell align='left'>{p.startDate}</TableCell>
                            <TableCell align='left'>{p.renewalDate}</TableCell>
                          </TableRow>   
                          )
                        })}
                      </TableBody>
                    </TableInformation>
                  </Box>
                )
              }
            </BoxMembership>
          </Grid>
        </Grid>
        <DialogComponent open={turnOffRenewal}>
        <DialogTitle marginTop={4} variant='h1'>
          Turn Off Annual Renewal
        </DialogTitle>
        <DialogContent sx={{overflowX: 'hidden', padding: '64px', height: '240px'}}>
          <Typography>Turning off your Annual Renewal will cancel your subscription at the end of the term and terminate all Music Licensing at the end of your annual agreement. This cancellation will be reported as a DROPPED account to the proper Performance Rights Organizations. If you are not closing your location, you will need to directly obtain licensing yourself or continue with the automatic renewal through FITRADIO.</Typography>
        </DialogContent>
        <DialogActions sx={{paddingX: '64px', marginBottom: 4}}>
          <ButtonCheckout onClick={() => cancelMembership()}>I Will Obtain My Own PRO Licensing</ButtonCheckout>
          <ButtonCheckout onClick={() => setTurnOffRenewal(false)}>Keep My Licenses Through FITRADIO</ButtonCheckout>
        </DialogActions>
        <LoaderWidget loader={loaderMembership} />
        </DialogComponent>
        <LoaderWidget loader={loader} />
      </Container>
      {
        openUpdatePaymentMethod && <UpdatePaymentMethod
          openDialog={setIsOpenUpdatePaymentMethod}
          setOpenDialog={setIsOpenUpdatePaymentMethod}
          setLoader={setLoader}
        />
      }
    </>
  );
};

export default SettingsPage;

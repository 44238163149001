import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Btn } from '../header.styles';
import { Divider, ListItemIcon, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrent } from '../../../../../app/features/business/businessSlice';
import { useNavigate } from 'react-router-dom';

const MultilocationsMenu = () => {
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { business, current } = useSelector((state) => state.business);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchBusiness = (businessId) => {
    dispatch(setCurrent(business.find((item) => (item.id === businessId)) || {}));
    handleClose();
  }

  const handleOnClickLocation = (id) => {
    handleClose();
    if (current.id === id) {
      navigate('/account')
    } else {
      switchBusiness(id)
    }
  }

  return (
    <div>
      <Btn
        variant='contained'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
      >
        Account
      </Btn>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
          Array.isArray(business) && business.map(location => (
            <MenuItem 
              selected={current?.id === location.id}
              key={location.id}
              onClick={() => handleOnClickLocation(location.id)}
            >
              <ListItemText>
                {location.name}
              </ListItemText>
            </MenuItem>
          ))
        }
        <Divider />
        <MenuItem onClick={() => { navigate('/account/new-location'); handleClose(); }}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          {
            !isProduction && <ListItemText>New Location</ListItemText>
          }
        </MenuItem>
      </Menu>
    </div>
  );
}

export default MultilocationsMenu;
